import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.set";
import _toConsumableArray from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.string.repeat";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.replace";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { MyCustomerIndex, MyCustomerRegression, MyCustomerTop, MyCustomerTransfer, MyShareSetshare, MyCustomerTransfoll, FollwupIndex, FollwupAdd, FollwupSettime, getLabelpeer as _getLabelpeer, getShareList, getCustomerLevel as _getCustomerLevel, MyCustomerImport, MyCustomerSetlevel, CustomerDeliverIndex as _CustomerDeliverIndex, Majorlist, agentFlower, callout, callNum, agentFlowerNameList, unlink, mobilePhone, addWeChatFriend, MyExcelModel, getIttnxm as _getIttnxm } from '@/api/ceshi_xiugai/whole';
import { getStructureListArr, getUnfollow } from '@/api/PublicAPI';
import { getPromoteCityList, getProjectTypesByCityId } from '@/api/ceshi_xiugai2/language';
import DisplaySettingser from '@/components/DisplaySettings/Settings';
import axios from 'axios';
import { getTokens } from '@/utils/auth';
import Head from '@/components/head/index';
import studentLevel from '@/components/studentLevel/index';
import TimeScreeningse from '@/components/timeScreeningse';
import privateSea from '@/components/privateSea';
import MyExportExcel from '@/components/MyExportExcel/index';
import mailList from '@/components/StaffSelect/mailListRadio';
import mailListEs from '@/components/StaffSelect/mailListRadioEs';
import mailListUp from '@/components/StaffSelect/mailListRadioUp';
import callRecord from '@/components/callRecord/index';
import mobileDialog from "./mobileDialog";
import wechatDialog from "./wechatDialog";
export default {
  name: 'CollegeManagement',
  data: function data() {
    var _ref;
    return _ref = {
      ketianyun: false,
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      Unfollow: '',
      //下次会发条数
      Unfollowshow: false,
      dialogBuild: false,
      //
      international: '',
      get_type: 0,
      sideEs: [],
      optionsEseven: [],
      showIfEs: false,
      dialogBuildEs: false,
      ImportTable: this.$store.getters.delivery,
      DeliveryToEs: 0,
      DeliveryToCustomersEs: [{
        id: 1,
        label: '是'
      }, {
        id: 0,
        label: '否'
      }],
      //
      ProjectShow: true,
      ProjectShow2: true,
      DeliveryToCustomers: [{
        id: 1,
        label: '是'
      }, {
        id: 2,
        label: '否'
      }],
      DeliveryTo: 1,
      showIf: true,
      side: [],
      optionsEs: [],
      listThematicChecked: [],
      user_arr: [{
        id: 1,
        realname: '今日待跟进',
        num: 5
      }, {
        id: 2,
        realname: '过期待跟进',
        num: 0
      }, {
        id: 3,
        realname: '今日已跟进',
        num: 0
      }],
      dialogPvVisibleDill: false,
      theDillEs_Even: false,
      theDillEs: [],
      repeat_listEven: false,
      repeat_Import: false,
      repeat_listEs: [],
      Import_listEs: [],
      dialogVisible: false,
      flower_Array: [],
      //花名
      flowerNameId: '',
      loading: false,
      followshow: false,
      follow_name: '',
      //最后流转人
      follow_Id: '',
      follow_page: 1,
      //分页
      follow_pagesize: 20,
      //多少条数据
      follow_Arr: [],
      //筛选最后流转人数组
      seaStimeShow: false,
      seaStimeName: '',
      sea_stime: '',
      sea_etime: '',
      sea_time_order: '0',
      Intendeds: [],
      Intended: '',
      //意向专业
      nexttime: '',
      //下次回访时间
      projectTextId: '',
      //报考项目id
      updateTimeShow: false,
      updateTime: '',
      update_stime: '',
      update_etime: '',
      update_time_order: '0',
      follwup_num_order: '0',
      next_follow_stime: '',
      next_follow_etime: '',
      start_follow_time: '',
      end_follow_time: '',
      title: '共享的客户',
      reverse: true,
      val: '',
      isShow: false,
      revisit: false,
      visisedbles: false,
      attributionshow: false,
      comNameshow: false,
      comWebsiteshow: false,
      nexttimeshow: false,
      createtimeshow: false,
      finalFollowshow: false,
      noteAppendedshow: false,
      ittnzyshow: false,
      createnameshow: false,
      lasttimeshow: false,
      remarksshow: false,
      labelpeersshow: false,
      structure_idshow: false,
      projectLiShow: false,
      //报考专业条件是否显示
      projects: [],
      //报考项目下拉数据
      showittnzy: '',
      //意向专业
      Batchstudentlevel: '',
      finalFollow: '',
      remarks: '',
      attribution: '',
      comName: '',
      comWebsite: '',
      noteAppended: '',
      labelpeers: '',
      //客户标签绑定值
      labelpeersEs: [],
      //客户标签
      source_client_id: '',
      //花名id
      createtime: '',
      //创建时间
      create_stime: '',
      //	创建开始时间
      create_etime: '',
      //	创建结束时间
      create_realname: '',
      bumenoptions: [],
      //组织部门
      structure_id: '',
      //组织部门
      structure_idEs: '',
      //组织部门
      revisitlevel: '',
      pickerOptions: {
        disabledDate: function disabledDate(v) {
          return v.getTime() < new Date().getTime() - 86400000;
        }
      },
      GetFieldInfodata2: [],
      projectBtnShow: true,
      // 项目方账户更多功能按钮显隐 默认值：true 显示更多功能按钮
      showIfs: false,
      cityVal: 1,
      cityArr: [],
      projectVal: '',
      projectArr: [],
      showIfEi: false,
      ImportCityVal: 1,
      ImportProjectVal: '',
      multipleSelection: [],
      //导出，客户信息
      displayOptions: ['序号', '客户名称', '电话号码', '归属地', '跟进人', '创建人', '下次回访日期', '最后跟进日期', '意向专业', '创建日期', '报考项目'],
      //导出，标签文字
      exportValueArr: ['customer_id', 'cname', 'mobile', 'mobile_address', 'follow_realname', 'create_realname', 'next_time', 'follw_time', 'ittnzy', 'create_time', 'ittnxm'],
      //导出，向后端传递的参数
      activities: [],
      //跟进时间线
      // 列表请求参数
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      followUp: false,
      start_sea_time: '',
      //进入私海时间（开始）
      end_sea_time: ''
    }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "start_follow_time", ''), "end_follow_time", ''), "source", [{
      //来源
      value: '0',
      label: '全部'
    }, {
      value: '1',
      label: '录入'
    }, {
      value: '2',
      label: '领取'
    }, {
      value: '3',
      label: '分配'
    }, {
      value: '4',
      label: '共享'
    }, {
      value: '5',
      label: '转移创建人'
    }, {
      value: '6',
      label: '转移跟进人'
    }]), "sourcese", ''), "Student", ''), "ClassSearch", ''), "customer_mobile", ''), "list_type", 3), "customer_level", ''), "followUpId", ''), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "followUpIdES", ''), "followUpEs", {
      //跟进
      name: '',
      theme: '',
      type: 1,
      next_time: ''
    }), "calendarTypeOptions", [
    //跟进类型
    {
      id: 1,
      label: '电话'
    }, {
      id: 2,
      label: 'QQ'
    }, {
      id: 3,
      label: '微信'
    }, {
      id: 4,
      label: '面聊'
    }]), "currentPage4", 1), "ptionse", 1), "listLoading", true), "tableData", []), "rules", {
      title: [{
        required: true,
        message: '请输入院校名称',
        trigger: 'blur'
      }]
    }), "textMap", {
      update: '编辑院校',
      create: '新建院校'
    }), "dialogStatus", ''), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "storageTime", []), "customer_id", []), "customer_data_id", []), "is_top", ''), "staffResult", []), "staffResultEs", []), "staffResultUp", []), "establish", '0'), "finalFollowUp", '0'), "nextVisit", '0'), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "mailListVisible", false), "mailListVisibleEs", false), "mailListVisibleUp", false), "radio", false), "radioEs", false), "radioUp", false), "condition", []), "transferor", ''), "transferorid", ''), "userName", ''), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "value1", ''), "customer", []), "displaySettings", []), "displaySettingsWhole", []), "dataTiem", ''), "dataEnd", ''), "NumberEs", 2), "resultES", ''), "cno", ''), "userDetails", []), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "dialogTableVisible", false), "callCid", ''), "cusName", ''), "cusPhone", ''), "sNum", ''), "eNum", ''), "callNumShow", false), "call_num_order", '0'), "dats", -1);
  },
  mounted: function mounted() {
    var _this2 = this;
    var self = this;
    var baseUrl = process.env.VUE_APP_BASE_API3;
    var socket = io(baseUrl);
    socket.on('new_msg', function (msg) {
      var fixMsg = JSON.parse(msg.replace(/&quot;/g, '"'));
      if (fixMsg.type === 'work_phone_dial') {
        var keys = localStorage.getItem('keys2');
        self.tableData[keys].WorkPhone = false;
        localStorage.setItem('WorkPhones', 'null');
      }
    });
    document.addEventListener('visibilitychange', self.handleVisiable);
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState == 'visible') {
        var slk = localStorage.getItem('operation');
        if (slk == 'opera') {
          self.getList(self.query.page, self.query.pagesize);
        }

        //当页面切换或者最小化时，在此做其他方法操作， 如切换页面时发出警告
      }
    });
    if (this.course != 1) {
      this.getLabelpeer();
      // this.followUpUp();
      this.getList(this.query.page, this.query.pagesize);
      this.getCustomerLevel();

      // 11111111
      this.callname = this.$store.getters.callname;
      this.callpass = this.$store.getters.callpass;
      this.cno = this.$store.getters.TianRun.cno;
      var that = this;
      // 控制表格滚动条
      this.$nextTick(function () {
        var mutationObserver = new MutationObserver(function (mutations) {
          that.setHeight();
        });
        mutationObserver.observe(_this2.$refs.multipleTable.bodyWrapper, {
          attributes: true,
          subtree: true
        });
      });
      this.majorDedMaj(); //意向专业
      this.flowerArray(); //花名
      this.followFun();
      this.getStructure();
      this.getIttnxm(); //报考项目数据
    } else {}
  },
  components: {
    Head: Head,
    TimeScreeningse: TimeScreeningse,
    privateSea: privateSea,
    studentLevel: studentLevel,
    mailList: mailList,
    mailListEs: mailListEs,
    mailListUp: mailListUp,
    DisplaySettingser: DisplaySettingser,
    MyExportExcel: MyExportExcel,
    callRecord: callRecord,
    mobileDialog: mobileDialog,
    wechatDialog: wechatDialog
  },
  methods: {
    handleVisiable: function handleVisiable(e) {
      //页面可见执行方法
      if (e.target.visibilityState === 'visible') {
        var WorkPhones = localStorage.getItem('WorkPhones');
        var keys = localStorage.getItem('keys2');
        if (WorkPhones == 'Calling') {
          this.tableData[keys].WorkPhone = true;
        } else {
          this.tableData[keys].WorkPhone = false;
        }
      }
    },
    // 工作手机拨打
    WorkCall: function WorkCall(row) {
      var tian = localStorage.getItem('Clink');
      if (tian == 'BUSY') {}
      // 调用子组件事件
      this.$refs.sendmsg.OutboundClick(row, 'workphone');
    },
    // 点击发送短信
    sendMsgClickFun: function sendMsgClickFun(row) {
      //
      this.$refs.sendmsg.isSendOrShowFun(row);
    },
    cancelDill: function cancelDill() {
      this.dialogPvVisibleDill = false;
      this.dialogPvVisibleEs = false;
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
      this.repeat_listEs = '';
      this.theDillEs = '';
    },
    //表头显示搜索框
    Activeshow: function Activeshow(item) {
      if (item === '下次回访日期') {
        this.nexttimeshow = true;
      } else if (item === '创建日期') {
        this.createtimeshow = true;
      } else if (item === '意向专业') {
        this.ittnzyshow = true;
      } else if (item === '创建人') {
        this.createnameshow = true;
      } else if (item === '备注') {
        this.remarksshow = true;
      } else if (item === '创建附注') {
        this.noteAppendedshow = true;
      } else if (item === '组织部门') {
        this.structure_idshow = true;
      } else if (item == '客户标签') {
        this.labelpeersshow = true;
      } else if (item == '最后跟进日期') {
        this.finalFollowshow = true;
      } else if (item == '归属地') {
        this.attributionshow = true;
      } else if (item === '最后流转人') {
        this.followshow = true;
      } else if (item === '进入私海日期') {
        this.seaStimeShow = true;
      } else if (item === '最后更新日期') {
        this.updateTimeShow = true;
      } else if (item === '推广项目') {
        this.comNameshow = true;
      } else if (item === '推广地址') {
        this.comWebsiteshow = true;
      } else if (item === '拨打次数') {
        this.callNumShow = true;
      } else if (item === '报考项目') {
        this.projectLiShow = true;
      }
    },
    //点×
    Deletesshow: function Deletesshow(item) {
      this.query.page = 1;
      if (item === '下次回访日期') {
        this.nexttimeshow = false;
        this.next_follow_stime = '';
        this.next_follow_etime = '';
        this.nexttime = '';
      } else if (item === '最后跟进日期') {
        this.finalFollowshow = false;
        this.start_follow_time = '';
        this.end_follow_time = '';
        this.finalFollow = '';
      } else if (item === '创建日期') {
        this.createtimeshow = false;
        this.create_stime = '';
        this.create_etime = '';
        this.createtime = '';
      } else if (item === '意向专业') {
        this.ittnzyshow = false;
        this.showittnzy = '';
      } else if (item === '创建人') {
        this.createnameshow = false;
        this.create_realname = '';
      } else if (item === '备注') {
        this.remarksshow = false;
        this.remarks = '';
      } else if (item === '创建附注') {
        this.noteAppendedshow = false;
        this.noteAppended = '';
      } else if (item === '组织部门') {
        this.structure_idshow = false;
        this.structure_id = '';
        this.structure_idEs = '';
      } else if (item === '客户标签') {
        this.labelpeersshow = false;
        this.labelpeers = '';
      } else if (item === '归属地') {
        this.attributionshow = false;
        this.attribution = '';
      } else if (item === '最后流转人') {
        this.followshow = false;
        this.follow_Id = '';
        this.follow_name = '';
      } else if (item === '进入私海日期') {
        this.seaStimeShow = false;
        this.sea_stime = '';
        this.sea_etime = '';
        this.seaStimeName = '';
      } else if (item === '最后更新日期') {
        this.updateTimeShow = false;
        this.update_stime = '';
        this.update_etime = '';
        this.updateTime = '';
      } else if (item === '推广项目') {
        this.comNameshow = false;
        this.comName = '';
      } else if (item === '推广地址') {
        this.comWebsiteshow = false;
        this.comWebsite = '';
      } else if (item === '拨打次数') {
        this.callNumShow = false;
        this.sNum = '';
        this.eNum = '';
      } else if (item === '报考项目') {
        this.projectLiShow = false;
        this.projectTextId = '';
      }
      this.getList(this.query.page, this.query.pagesize);
    },
    radioFix: function radioFix() {
      if (this.listThematicChecked.length > 1) {
        this.listThematicChecked.splice(0, 1);
      }
      this.getList(this.query.page, this.query.pagesize);
    },
    followUpUp: function followUpUp() {
      var _this3 = this;
      MyCustomerIndex({
        //今日待跟进数量
        page: String(this.query.page),
        pagesize: String(this.query.pagesize),
        list_type: this.list_type,
        follow_type: '1'
      }).then(function (respomse) {
        _this3.user_arr[0].num = respomse.data.total;
      });
      MyCustomerIndex({
        //过期待跟进数量
        page: String(this.query.page),
        pagesize: String(this.query.pagesize),
        list_type: this.list_type,
        follow_type: '2'
      }).then(function (respomse) {
        _this3.user_arr[1].num = respomse.data.total;
      });
      MyCustomerIndex({
        //今日已跟进数量
        page: String(this.query.page),
        pagesize: String(this.query.pagesize),
        list_type: this.list_type,
        follow_type: '3'
      }).then(function (respomse) {
        _this3.user_arr[2].num = respomse.data.follow_num;
      });
    },
    dialogFork: function dialogFork() {
      this.showIfs = false;
      this.showIfEi = false;
      this.cityVal = '';
      this.projectVal = '';
      this.ImportCityVal = '';
      this.ImportProjectVal = '';
      this.sideEs = '';
    },
    customerLabel: function customerLabel(item) {
      //客户标签筛选
      this.labelpeers = item;
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    majorDedMaj: function majorDedMaj() {
      var _this4 = this;
      //意向专业默认
      Majorlist().then(function (res) {
        _this4.Intendeds = res.data.major_list;
      });
    },
    followFun: function followFun() {
      var _this5 = this;
      //筛选跟进人数据
      getShareList({
        page: String(this.follow_page),
        pagesize: String(this.follow_pagesize)
      }).then(function (res) {
        _this5.follow_Arr = res.data.data;
      });
    },
    followName: function followName() {
      var _this6 = this;
      if (this.follow_name == '') {
        this.follow_page++;
        getShareList({
          page: String(this.follow_page),
          pagesize: String(this.follow_pagesize)
        }).then(function (res) {
          res.data.data.forEach(function (item) {
            _this6.follow_Arr.push(item);
          });
        });
      }
    },
    followMethod: function followMethod(val) {
      var _this7 = this;
      this.follow_name = val;
      this.follow_page = 1;
      getShareList({
        realname: val,
        page: String(this.follow_page),
        pagesize: String(this.follow_pagesize)
      }).then(function (res) {
        _this7.follow_Arr = res.data.data;
      });
    },
    followidEs: function followidEs(val) {
      this.follow_name = val;
      this.$refs.clearValue.query = '';
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    flowerArray: function flowerArray() {
      var _this8 = this;
      //花名
      agentFlowerNameList().then(function (res) {
        _this8.flower_Array = res.data;
      });
    },
    getStructure: function getStructure() {
      var _this9 = this;
      getStructureListArr().then(function (respomse) {
        _this9.bumenoptions = respomse.data;
      });
    },
    getList: function getList(page, pagesize) {
      var _this$resultES,
        _MyCustomerIndex,
        _this10 = this;
      //账号客户列表

      this.listLoading = true;
      this.resultES = (_this$resultES = {
        start_sea_time: this.start_sea_time,
        end_sea_time: this.end_sea_time,
        start_follow_time: this.start_follow_time,
        end_follow_time: this.end_follow_time,
        type: Number(this.sourcese),
        customer_levels: this.Student,
        customer_name: this.ClassSearch,
        customer_mobile: this.customer_mobile,
        list_type: this.list_type,
        ittnzy: this.Intended,
        follow_type: String(this.listThematicChecked),
        next_follow_stime: this.next_follow_stime,
        next_follow_etime: this.next_follow_etime
      }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_this$resultES, "start_follow_time", this.start_follow_time), "end_follow_time", this.end_follow_time), "ittnzy", this.showittnzy), "source_client_id", this.source_client_id), "create_stime", this.create_stime), "create_etime", this.create_etime), "roam_user_ids", String(this.follow_name)), "create_realname", this.create_realname), "structure_ids", this.structure_idEs), "create_time_order", this.establish), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_this$resultES, "follow_time_order", this.finalFollowUp), "follwup_num_order", this.follwup_num_order), "next_time_order", this.nextVisit), "sea_stime", this.sea_stime), "sea_etime", this.sea_etime), "sea_time_order", this.sea_time_order), "update_time_order", this.update_time_order), "update_stime", this.update_stime), "update_etime", this.update_etime), "labelpeers", this.labelpeers), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_this$resultES, "notes", this.remarks), "mobile_address", this.attribution), "create_notes", this.noteAppended), "ittnxl", this.projectTextId));
      MyCustomerIndex((_MyCustomerIndex = {
        page: String(page),
        pagesize: String(pagesize),
        start_sea_time: this.start_sea_time,
        end_sea_time: this.end_sea_time,
        start_follow_time: this.start_follow_time,
        end_follow_time: this.end_follow_time,
        type: Number(this.sourcese),
        customer_levels: this.Student,
        customer_name: this.ClassSearch,
        customer_mobile: this.customer_mobile,
        list_type: this.list_type,
        ittnzy: this.Intended,
        roam_user_ids: String(this.follow_name),
        follow_type: String(this.listThematicChecked),
        next_follow_stime: this.next_follow_stime,
        next_follow_etime: this.next_follow_etime
      }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_MyCustomerIndex, "start_follow_time", this.start_follow_time), "end_follow_time", this.end_follow_time), "ittnzy", this.showittnzy), "source_client_id", this.source_client_id), "create_stime", this.create_stime), "create_etime", this.create_etime), "create_realname", this.create_realname), "structure_ids", this.structure_idEs), "create_time_order", this.establish), "follow_time_order", this.finalFollowUp), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_MyCustomerIndex, "follwup_num_order", this.follwup_num_order), "next_time_order", this.nextVisit), "sea_stime", this.sea_stime), "sea_etime", this.sea_etime), "sea_time_order", this.sea_time_order), "update_time_order", this.update_time_order), "update_stime", this.update_stime), "update_etime", this.update_etime), "labelpeers", this.labelpeers), "notes", this.remarks), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_MyCustomerIndex, "mobile_address", this.attribution), "create_notes", this.noteAppended), "communication_name", this.comName), "communication_website", this.comWebsite), "calls_num", this.sNum), "calle_num", this.eNum), "call_num_order", this.call_num_order), "ittnxl", this.projectTextId))).then(function (respomse) {
        var data = respomse.data;
        var WorkPhones = localStorage.getItem('WorkPhones');
        var Cid = localStorage.getItem('Cid');
        data.data.map(function (item) {
          item.create_notesE = item.create_notes;
          if (item.create_notes && item.create_notes.length > 20) {
            item.create_notes = item.create_notes.substring(0, 20) + '......';
          }
          if (WorkPhones == 'Calling') {
            if (Cid == item.customer_id) {
              item.WorkPhone = true;
            }
          } else {
            item.WorkPhone = false;
          }
          item.message = '';
          item.remark = item.cname;
        });
        _this10.tableData = data.data;
        _this10.query.total = data.total;
        _this10.listLoading = false;
        _this10.displaySettingsWhole = [];
        _this10.exportValueArr = [];
        if (respomse.data.fields) {
          var indexCreater = respomse.data.fields.indexOf('创建人');
          if (indexCreater !== -1) {
            respomse.data.fields.splice(indexCreater, 1);
          }
        }
        if (respomse.data.base_fields) {
          delete respomse.data.base_fields.create_realname;
        }
        _this10.displaySettings = respomse.data.fields;
        localStorage.setItem('names', JSON.stringify(respomse.data.fields));
        for (var item in respomse.data.base_fields) {
          _this10.exportValueArr.push(item);
          _this10.displaySettingsWhole.push(respomse.data.base_fields[item]);
        }
        var slk = localStorage.getItem('operation');
        if (slk == 'opera') {
          localStorage.removeItem('operation');
        }
      });
    },
    EntryName: function EntryName(val) {
      var _this11 = this;
      if (val == 0) {
        this.showIfs = true;
        getPromoteCityList().then(function (res) {
          _this11.cityArr = res.data;
        });
        getProjectTypesByCityId({
          city_id: this.cityVal
        }).then(function (res) {
          _this11.projectArr = res.data;
          _this11.projectVal = res.data[0].project_type;
        });
      } else {
        this.showIfs = false;
      }
    },
    EntryNames: function EntryNames(val) {
      var _this12 = this;
      if (val == 0) {
        this.showIfEi = true;
        getPromoteCityList().then(function (res) {
          _this12.cityArr = res.data;
        });
        getProjectTypesByCityId({
          city_id: this.ImportCityVal
        }).then(function (res) {
          _this12.projectArr = res.data;
          _this12.ImportProjectVal = res.data[0].project_type;
        });
      } else {
        this.showIfEi = false;
      }
    },
    cityClick: function cityClick(val) {
      var _this13 = this;
      if (val != '') {
        getProjectTypesByCityId({
          city_id: val
        }).then(function (res) {
          _this13.projectArr = res.data;
          _this13.projectVal = res.data[0].project_type;
        });
        this.ProjectShow2 = false;
      } else {
        this.ProjectShow2 = true;
      }
    },
    ImportCityClick: function ImportCityClick(val) {
      var _this14 = this;
      if (val != '') {
        getProjectTypesByCityId({
          city_id: val
        }).then(function (res) {
          _this14.projectArr = res.data;
          _this14.ImportProjectVal = res.data[0].project_type;
        });
        this.ProjectShow = false;
      } else {
        this.ProjectShow = true;
      }
    },
    getCustomerLevel: function getCustomerLevel() {
      var _this15 = this;
      _getCustomerLevel().then(function (res) {
        res.data.map(function (e) {
          e.label = e.name;
          e.value = e.id;
        });
        for (var item in res.data) {
          if (res.data[item].children != undefined) {
            res.data[item].children.map(function (dats) {
              dats.label = dats.name;
              dats.value = dats.id;
            });
          }
        }
        _this15.GetFieldInfodata2 = res.data;
      });
    },
    // 设置滚动条相关信息1111111111
    setHeight: function setHeight() {
      var widthValue = document.getElementById('customerTable').clientWidth + 'px';
      this.tableBodyWidth = document.getElementById('customerTable').childNodes[1].childNodes[0].clientWidth + 'px';
      var div = document.getElementById('tableLeft');
      div.style.width = widthValue;
      var div2 = document.getElementById('bodyLeft');
      div2.style.width = this.tableBodyWidth;
    },
    // 控制表格滚动条1111111
    exterHandleScroll: function exterHandleScroll() {
      var table = this.$refs.multipleTable.bodyWrapper;
      table.scrollLeft = this.$refs.tableLeft.scrollLeft;
    },
    // 客户标签
    getLabelpeer: function getLabelpeer() {
      var _this16 = this;
      _getLabelpeer().then(function (res) {
        //客户标签
        res.data.map(function (itemEs) {
          itemEs.id = String(itemEs.id);
        });
        _this16.labelpeersEs = res.data;
        _this16.customer = res.data;
      });
    },
    callNums: function callNums() {
      //拨打次数筛选
      this.query.page = 1;
      if (this.sNum == '' && this.eNum == '') {
        this.sNum = '';
        this.eNum = '';
        this.getList(this.query.page, this.query.pagesize);
      } else if (this.sNum == '' || this.eNum == '') {
        this.$message({
          message: '请输入完整次数段',
          type: 'warning'
        });
      } else if (Number(this.sNum) > Number(this.eNum)) {
        this.$message({
          message: '请输入正确次数范围',
          type: 'warning'
        });
      } else {
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    sortEs: function sortEs(column, prop, order) {
      // ascending 升序 、 descending降序 、 null 不排序
      if (column.prop == 'create_time') {
        //创建日期
        if (column.order == 'ascending') {
          this.establish = '1';
        } else if (column.order == 'descending') {
          this.establish = '2';
        } else {
          this.establish = '0';
        }
        this.nextVisit = '0';
        this.finalFollowUp = '0';
        this.sea_time_order = '0';
        this.update_time_order = '0';
        this.follwup_num_order = '0';
        this.call_num_order = '0';
      } else if (column.prop == 'follw_time') {
        //最后跟进日期
        if (column.order == 'ascending') {
          this.finalFollowUp = '1';
        } else if (column.order == 'descending') {
          this.finalFollowUp = '2';
        } else {
          this.finalFollowUp = '0';
        }
        this.establish = '0';
        this.nextVisit = '0';
        this.sea_time_order = '0';
        this.update_time_order = '0';
        this.follwup_num_order = '0';
        this.call_num_order = '0';
      } else if (column.prop == 'next_time') {
        //下次回访日期
        if (column.order == 'ascending') {
          this.nextVisit = '1';
        } else if (column.order == 'descending') {
          this.nextVisit = '2';
        } else {
          this.nextVisit = '0';
        }
        this.establish = '0';
        this.finalFollowUp = '0';
        this.sea_time_order = '0';
        this.update_time_order = '0';
        this.follwup_num_order = '0';
        this.call_num_order = '0';
      } else if (column.prop == 'sea_time') {
        //进入私海日期
        if (column.order == 'ascending') {
          this.sea_time_order = '1';
        } else if (column.order == 'descending') {
          this.sea_time_order = '2';
        } else {
          this.sea_time_order = '0';
        }
        this.nextVisit = '0';
        this.establish = '0';
        this.finalFollowUp = '0';
        this.update_time_order = '0';
        this.follwup_num_order = '0';
        this.call_num_order = '0';
      } else if (column.prop == 'update_time') {
        //最后更新日期
        if (column.order == 'ascending') {
          this.update_time_order = '1';
        } else if (column.order == 'descending') {
          this.update_time_order = '2';
        } else {
          this.update_time_order = '0';
        }
        this.nextVisit = '0';
        this.establish = '0';
        this.finalFollowUp = '0';
        this.sea_time_order = '0';
        this.follwup_num_order = '0';
        this.call_num_order = '0';
      } else if (column.prop == 'follow_num') {
        //跟进排序
        if (column.order == 'ascending') {
          this.follwup_num_order = '1';
        } else if (column.order == 'descending') {
          this.follwup_num_order = '2';
        } else {
          this.follwup_num_order = '0';
        }
        this.nextVisit = '0';
        this.establish = '0';
        this.finalFollowUp = '0';
        this.sea_time_order = '0';
        this.update_time_order = '0';
        this.call_num_order = '0';
      } else if (column.prop == 'all_call_num') {
        //拨打次数排序
        if (column.order == 'ascending') {
          this.call_num_order = '1';
        } else if (column.order == 'descending') {
          this.call_num_order = '2';
        } else {
          this.call_num_order = '0';
        }
        this.nextVisit = '0';
        this.establish = '0';
        this.finalFollowUp = '0';
        this.sea_time_order = '0';
        this.update_time_order = '0';
        this.follwup_num_order = '0';
      }
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    viewRecord: function viewRecord(id, name, phone) {
      this.dialogTableVisible = true;
      this.callCid = id;
      this.cusName = name;
      this.cusPhone = phone;
    },
    OutboundClick: function OutboundClick(row) {
      // 调用子组件事件
      this.$refs.sendmsg.OutboundClick(row, 'callout', this.cno);
    },
    Import: function Import() {
      var _this17 = this;
      this.dialogBuildEs = true;
      agentFlower().then(function (res) {
        _this17.optionsEseven = res.data;
      });
    },
    DeliveryToCliEs: function DeliveryToCliEs(val) {
      if (val == 1) {
        this.showIfEs = true;
      } else if (val == 0) {
        this.showIfEs = false;
        this.sideEs = '';
      }
    },
    //信息导入 xlsx
    TeacherExcel: function TeacherExcel(param) {
      var _this18 = this;
      if (this.DeliveryToEs == 1) {
        if (this.sideEs != '') {
          var newfile = new FormData();
          newfile.append('file', param.file);
          newfile.append('agent_ids', String(this.sideEs));
          newfile.append('agent_type', String(this.DeliveryToEs));
          newfile.append('delivery_city_id', String(this.ImportCityVal));
          newfile.append('delivery_project', String(this.ImportProjectVal));
          this.dialogVisible = true;
          MyCustomerImport(newfile).then(function (res) {
            if (res.msg == 'success') {
              _this18.$notify({
                title: '成功',
                message: '上传成功',
                type: 'success',
                duration: 2000
              });
              _this18.dialogVisible = false;
              _this18.dialogBuildEs = false;
              _this18.repeat_Import = false;
              _this18.dialogPvVisibleDill = false;
            } else {
              if (res.msg.zhuangdan.length >= 1) {
                _this18.theDillEs = res.msg.zhuangdan;
                _this18.theDillEs_Even = true;
              } else {
                _this18.theDillEs = [];
                _this18.theDillEs_Even = false;
              }
              if (res.msg.send_customer.length >= 1) {
                _this18.repeat_listEs = res.msg.send_customer;
                _this18.repeat_listEven = true;
              } else {
                _this18.repeat_listEs = [];
                _this18.repeat_listEven = false;
              }
              if (res.msg.error.length >= 1) {
                _this18.Import_listEs = res.msg.error;
                _this18.repeat_Import = true;
              } else {
                _this18.Import_listEs = [];
                _this18.repeat_Import = false;
              }
              // this.epeat_listEven = false;
              _this18.dialogVisible = false;
              _this18.dialogBuildEs = false;
              if (_this18.repeat_Import || _this18.repeat_listEven || _this18.theDillEs_Even) {
                _this18.dialogPvVisibleDill = true;
              } else {
                _this18.dialogPvVisibleDill = false;
                _this18.$notify({
                  title: '成功',
                  message: '上传成功',
                  type: 'success',
                  duration: 2000
                });
              }
            }
          });
        } else {
          this.$message({
            type: 'warning',
            message: '选择外送的项目方名称'
          });
        }
      } else {
        var _newfile = new FormData();
        _newfile.append('file', param.file);
        _newfile.append('agent_ids', String(this.sideEs));
        _newfile.append('agent_type', String(this.DeliveryToEs));
        this.dialogVisible = true;
        MyCustomerImport(_newfile).then(function (res) {
          if (res.msg == 'success') {
            _this18.$notify({
              title: '成功',
              message: '上传成功',
              type: 'success',
              duration: 2000
            });
            _this18.dialogVisible = false;
            _this18.dialogBuildEs = false;
            _this18.repeat_Import = false;
            _this18.dialogPvVisibleDill = false;
          } else {
            if (res.msg.zhuangdan.length >= 1) {
              _this18.theDillEs = res.msg.zhuangdan;
              _this18.theDillEs_Even = true;
            } else {
              _this18.theDillEs = [];
              _this18.theDillEs_Even = false;
            }
            if (res.msg.send_customer.length >= 1) {
              _this18.repeat_listEs = res.msg.send_customer;
              _this18.repeat_listEven = true;
            } else {
              _this18.repeat_listEs = [];
              _this18.repeat_listEven = false;
            }
            if (res.msg.error.length >= 1) {
              _this18.Import_listEs = res.msg.error;
              _this18.repeat_Import = true;
            } else {
              _this18.Import_listEs = [];
              _this18.repeat_Import = false;
            }
            // this.epeat_listEven = false;
            _this18.dialogVisible = false;
            _this18.dialogBuildEs = false;
            if (_this18.repeat_Import || _this18.repeat_listEven || _this18.theDillEs_Even) {
              _this18.dialogPvVisibleDill = true;
            } else {
              _this18.dialogPvVisibleDill = false;
              _this18.$notify({
                title: '成功',
                message: '上传成功',
                type: 'success',
                duration: 2000
              });
            }
          }
        });
      }
    },
    sourceVal: function sourceVal(val) {
      //来源
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    IntendedMajor: function IntendedMajor() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    //花名
    flowerArrayEs: function flowerArrayEs() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    //意向专业
    shouwittnzyclick: function shouwittnzyclick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    //组织部门
    structureclick: function structureclick(val) {
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      var adad = share == ',' ? '' : share;
      this.structure_idEs = adad;
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    nextDateTime: function nextDateTime(item) {
      //回访时间选择
      this.query.page = 1;
      if (this.nexttime == null) {
        this.next_follow_stime = '';
        this.next_follow_etime = '';
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.next_follow_stime = this.nexttime[0].toString().substring(0, 10);
        this.next_follow_etime = this.nexttime[1].toString().substring(0, 10);
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    FinalFollowUpEs: function FinalFollowUpEs() {
      //最后跟进日期筛选
      this.query.page = 1;
      if (this.finalFollow == null) {
        this.start_follow_time = '';
        this.end_follow_time = '';
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.start_follow_time = this.finalFollow[0].toString().substring(0, 10);
        this.end_follow_time = this.finalFollow[1].toString().substring(0, 10);
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    //创建时间
    creatDateTime: function creatDateTime() {
      this.query.page = 1;
      if (this.createtime == null) {
        this.create_stime = '';
        this.create_etime = '';
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.create_stime = this.createtime[0].toString().substring(0, 10);
        this.create_etime = this.createtime[1].toString().substring(0, 10);
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    seaStimeClick: function seaStimeClick() {
      //进入私海日期筛选
      this.query.page = 1;
      if (this.seaStimeName == null) {
        this.sea_stime = '';
        this.sea_etime = '';
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.sea_stime = this.seaStimeName[0].toString().substring(0, 10);
        this.sea_etime = this.seaStimeName[1].toString().substring(0, 10);
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    updateTimeClick: function updateTimeClick() {
      //最后更新日期筛选
      this.query.page = 1;
      if (this.updateTime == null) {
        this.update_stime = '';
        this.update_etime = '';
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.update_stime = this.updateTime[0].toString().substring(0, 10);
        this.update_etime = this.updateTime[1].toString().substring(0, 10);
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    //创建人
    createname: function createname() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    remarksClick: function remarksClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    attributionClick: function attributionClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    comNameClick: function comNameClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    comWebsiteClick: function comWebsiteClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    noteAppendedClick: function noteAppendedClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    // 学员级别
    studentLevel: function studentLevel(val) {
      this.Student = val;
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    DeliveryToCli: function DeliveryToCli(val) {
      if (val == 1) {
        this.showIf = true;
      } else {
        this.showIf = false;
      }
    },
    dialogClick: function dialogClick() {
      this.dialogBuild = false;
      // this.showIf = false;
      this.showIfs = false;
      this.cityVal = 1;
      this.side = '';
      this.projectVal = '';
    },
    NewCustomer: function NewCustomer(val) {
      //编辑
      if (val == 1) {
        if (this.DeliveryTo == 1) {
          if (this.side == '') {
            this.$message({
              message: '请选择外送的项目方名称',
              type: 'warning'
            });
          } else {
            // localStorage.setItem('operation1', '编辑');
            this.dialogBuild = false;
            var addUrl = this.$router.resolve({
              path: '/addCustomer?pid=1&agent_type=' + this.DeliveryTo + '&agent_ids=' + this.side + '&cityId=' + this.cityVal + '&projectId=' + this.projectVal
            });
            window.open(addUrl.href, '_blank');
          }
        } else {
          this.dialogBuild = false;
          var _addUrl = this.$router.resolve({
            path: '/addCustomer?pid=1'
          });
          window.open(_addUrl.href, '_blank');
        }
      } else {
        if (String(this.customer_id) == '') {
          this.$message({
            message: '请选择客户',
            type: 'warning'
          });
        } else {
          if (this.customer_id.length >= 2) {
            this.$message({
              message: '请选择一个客户',
              type: 'warning'
            });
          } else {
            var _addUrl2 = this.$router.resolve({
              path: '/addCustomer?pid=2&customer_id=' + String(this.customer_id) + '&customer_data_id=' + this.customer_data_id
            });
            window.open(_addUrl2.href, '_blank');
          }
        }
      }
    },
    delivery: function delivery() {
      var _this19 = this;
      if (this.side == '') {
        this.$message({
          type: 'warning',
          message: '请选择外送的项目方'
        });
      } else {
        this.aaaaEs = true;
        var loading = this.$loading({
          lock: this.aaaaEs,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        loading.close();
        var aaa = {
          customer_data_ids: String(this.customer_data_id),
          agent_ids: String(this.side)
        };
        if (this.side == 0) {
          aaa.delivery_city_id = this.deliveryCityVal;
          aaa.delivery_project = this.deliveryProjectVal;
        }
        sendcustomer(aaa).then(function (res) {
          _this19.aaaaEs = false;
          loading.close();
          if (res.data.knock != '') {
            res.data.knock.forEach(function (item) {
              for (var i in item.list) {
                if (item.list[i] != '') {
                  _this19.theDillEs = res.data.knock;
                }
              }
            });
          } else {
            _this19.theDillEs = res.data.knock;
          }
          if (_this19.theDillEs != '') {
            _this19.theDillEs_Even = true;
          } else {
            _this19.theDillEs_Even = false;
          }
          if (res.data.repeat == '') {
            _this19.repeat_listEven = false;
          } else {
            _this19.repeat_listEven = true;
            _this19.repeat_listEs = res.data.repeat;
          }
          if (_this19.theDillEs == '' && res.data.repeat == '') {
            _this19.$notify({
              title: '成功',
              message: '外送成功',
              type: 'success',
              duration: 2000
            });
            _this19.getList(_this19.query.page, _this19.query.pagesize);
            _this19.dialogPvVisibleEs = false;
          } else {
            _this19.dialogPvVisibleDill = true;
          }
        });
      }
    },
    //zml下次回访条数
    getUnfollowmsg: function getUnfollowmsg() {
      var _this20 = this;
      var next_time = this.followUpEs.next_time == null ? '' : this.followUpEs.next_time.toString().substr(0, 10);
      if (next_time != '') {
        getUnfollow({
          next_time: next_time
        }).then(function (res) {
          if (res.data == '') {
            _this20.Unfollowshow = false;
          } else {
            _this20.Unfollowshow = true;
            _this20.Unfollow = res.data;
          }
        });
      }
    },
    DeliveryToClick: function DeliveryToClick() {
      var _this21 = this;
      if (this.$store.getters.delivery == 1) {
        this.dialogBuild = true;
        this.side = [];
        agentFlower({
          is_send: '2'
        }).then(function (res) {
          _this21.optionsEs = res.data;
        });
      } else {
        // localStorage.setItem('operation1', '新建');
        // this.$router.push({ path: '/addCustomer?pid=1' });
        var addUrl = this.$router.resolve({
          path: '/addCustomer?pid=1'
        });
        window.open(addUrl.href, '_blank');
      }
    },
    CustomerDeliverIndex: function CustomerDeliverIndex() {
      var _this22 = this;
      //外送公海客户
      if (String(this.customer_id) == '') {
        this.$message({
          message: '请选择客户',
          type: 'warning'
        });
      } else {
        this.$confirm('数据外送公海池后会被送到合作项目方，确认外送？', '外送公海', {
          confirmButtonText: '继续外送',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _CustomerDeliverIndex({
            customer_ids: String(_this22.customer_id),
            customer_data_ids: String(_this22.customer_data_id)
          }).then(function (res) {
            _this22.$notify({
              title: '成功',
              message: String(res.data),
              type: 'success',
              duration: 2000
            });
            _this22.query.page = 1;
            _this22.getList(_this22.query.page, _this22.query.pagesize);
          });
        }).catch(function () {});
      }
    },
    regression: function regression() {
      var _this23 = this;
      //回归公海
      if (String(this.international) == '') {
        this.$message({
          message: '请选择客户',
          type: 'warning'
        });
      } else {
        var zifu = '';
        this.$confirm('请确认是否将所选的' + this.international.length + '客户回归公海?', '回归公海', {
          confirmButtonText: '继续回归',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this23.listLoading = true;
          MyCustomerRegression({
            customer_data_ids: String(_this23.international)
          }).then(function (res) {
            _this23.listLoading = false;
            for (var i in res.data) {
              zifu += "<p>\u5BA2\u6237\uFF1A".concat(res.data[i], "</p>");
            }
            _this23.$notify({
              title: '提示',
              dangerouslyUseHTMLString: true,
              message: zifu
            });
            _this23.getList(_this23.query.page, _this23.query.pagesize);
          });
        });
      }
    },
    topOfList: function topOfList(item, id) {
      var _this24 = this;
      //置顶
      MyCustomerTop({
        is_top: item,
        customer_data_id: String(id)
      }).then(function (res) {
        if (item == 1) {
          _this24.$notify({
            title: '成功',
            message: '成功置顶',
            type: 'success',
            duration: 2000
          });
        } else {
          _this24.$notify({
            title: '成功',
            message: '成功取消置顶',
            type: 'success',
            duration: 2000
          });
        }
        _this24.getList(_this24.query.page, _this24.query.pagesize);
      });
    },
    transfer: function transfer() {
      //转移客户
      if (String(this.customer_id) == '') {
        this.$message({
          type: 'info',
          message: '请选择客户'
        });
      } else {
        this.radio = true;
        this.mailListVisible = true;
      }
    },
    followUpPerson: function followUpPerson() {
      //转移客户
      if (String(this.customer_id) == '') {
        this.$message({
          type: 'info',
          message: '请选择客户'
        });
      } else {
        this.radioUp = true;
        this.mailListVisibleUp = true;
      }
    },
    share: function share() {
      //共享客户
      if (String(this.customer_id) == '') {
        this.$message({
          type: 'info',
          message: '请选择客户'
        });
      } else {
        this.radioEs = true;
        this.mailListVisibleEs = true;
      }
    },
    maillistRadio: function maillistRadio(resultNameArr, result) {
      var _this25 = this;
      //选择转移创建人给谁
      var array = [];
      this.userDetails.map(function (item) {
        item.mobileName = item.mobile + '【' + item.cname + '】';
        array.push(String(item.mobileName));
      });
      var aaa = String(resultNameArr);
      var bbb = String(array.join('、'));
      this.$confirm("\u786E\u5B9A\u5C06&nbsp;&nbsp;".concat(bbb, "&nbsp;&nbsp;\u8F6C\u79FB\u521B\u5EFA\u4EBA\u7ED9<b>&nbsp;&nbsp;").concat(aaa, "</b>?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(function () {
        _this25.listLoading = true;
        _this25.transferor = String(result);
        MyCustomerTransfer({
          customer_ids: String(_this25.customer_id),
          user_id: _this25.transferor
        }).then(function (res) {
          _this25.listLoading = false;
          _this25.$notify({
            title: '提示',
            message: res.data.toString(),
            type: 'success',
            duration: 3000
          });
          _this25.getList(_this25.query.page, _this25.query.pagesize);
        });
      }).catch(function () {
        _this25.$message({
          type: 'info',
          message: '已取消转移创建人'
        });
      });
    },
    staffSelectConfirmEs: function staffSelectConfirmEs(resultNameArr, result) {
      var _this26 = this;
      //选择共享
      var array = [];
      this.userDetails.map(function (item) {
        item.mobileName = item.mobile + '【' + item.cname + '】';
        array.push(String(item.mobileName));
      });
      var aaa = String(resultNameArr);
      var bbb = String(array.join('、'));
      this.$confirm("\u786E\u5B9A\u5C06&nbsp;&nbsp;".concat(bbb, "&nbsp;&nbsp;\u5171\u4EAB\u7ED9<b>&nbsp;&nbsp;").concat(aaa, "</b>?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(function () {
        _this26.transferorid = String(result);
        _this26.listLoading = true;
        MyShareSetshare({
          customer_ids: String(_this26.customer_id),
          user_id: _this26.transferorid
        }).then(function (res) {
          _this26.listLoading = false;
          _this26.$notify({
            title: '提示',
            message: res.data.toString(),
            type: 'success',
            duration: 2000
          });
          _this26.getList(_this26.query.page, _this26.query.pagesize);
        });
      }).catch(function () {
        _this26.$message({
          type: 'info',
          message: '已取消共享'
        });
      });
    },
    SharedSustomersUp: function SharedSustomersUp(resultNameArr, result) {
      var _this27 = this;
      //选择转移跟进人给谁
      var array = [];
      this.userDetails.map(function (item) {
        item.mobileName = item.mobile + '【' + item.cname + '】';
        array.push(String(item.mobileName));
      });
      var aaa = String(resultNameArr);
      var bbb = String(array.join('、'));
      this.$confirm("\u786E\u5B9A\u5C06&nbsp;&nbsp;".concat(bbb, "&nbsp;&nbsp;\u8F6C\u79FB\u8DDF\u8FDB\u4EBA\u7ED9<b>&nbsp;&nbsp;").concat(aaa, "</b>?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(function () {
        _this27.listLoading = true;
        _this27.transferorid = String(result);
        MyCustomerTransfoll({
          customer_data_ids: String(_this27.customer_data_id),
          user_id: _this27.transferorid
        }).then(function (res) {
          _this27.listLoading = false;
          _this27.$notify({
            title: '提示',
            message: res.data.toString(),
            type: 'success',
            duration: 2000
          });
          _this27.getList(_this27.query.page, _this27.query.pagesize);
        });
      }).catch(function () {
        _this27.$message({
          type: 'info',
          message: '已取消转移创建人'
        });
      });
    },
    ClassSearchInput: function ClassSearchInput() {
      this.query.page = 1;
      if (this.ClassSearch == '') {
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.getList(this.query.page, this.query.pagesize, this.ClassSearch);
      }
    },
    customerMobileInput: function customerMobileInput() {
      this.query.page = 1;
      if (this.customer_mobile == '') {
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.getList(this.query.page, this.query.pagesize, this.customer_mobile);
      }
    },
    handleSizeChange: function handleSizeChange(val) {
      //每页 ${val} 条
      this.query.pagesize = val;
      this.getList(this.query.page, val);
    },
    //翻页
    handleCurrentChange: function handleCurrentChange(val) {
      this.query.page = val;
      this.getList(val, this.query.pagesize);
    },
    followUpCustomers: function followUpCustomers(id) {
      var _this28 = this;
      //跟进弹窗
      this.activities = '';
      this.Batchstudentlevel = this.getFathersByid(id.customer_level_id, this.GetFieldInfodata2);
      this.customer_data_id = id.customer_data_id;
      this.followUpEs.type = 1;
      this.followUp = true;
      this.followUpEs.name = id.cname;
      this.followUpId = id.customer_id;
      this.followUpIdES = id.customer_data_id;
      this.followUpEs.theme = '';
      this.followUpEs.next_time = '';
      this.Unfollowshow = false;
      FollwupIndex({
        id: id.customer_id,
        customer_data_id: id.customer_data_id,
        is_realname: 2
      }).then(function (res) {
        res.data.map(function (res) {
          if (res.type == 1) {
            res.type = '电话';
          } else if (res.type == 2) {
            res.type = 'QQ';
          } else if (res.type == 3) {
            res.type = '微信';
          } else if (res.type == 4) {
            res.type = '面聊';
          }
          res.next_time = String(res.next_time);
        });
        _this28.activities = res.data;
      });
    },
    NewFollowupRecordSure: function NewFollowupRecordSure() {
      var _this29 = this;
      //添加跟进
      if (this.followUpEs.type == '') {
        this.$message({
          type: 'warning',
          message: '请选择跟进类型'
        });
      } else if (this.followUpEs.theme == '') {
        this.$message({
          type: 'warning',
          message: '请输入主题'
        });
      } else {
        var next_time = this.followUpEs.next_time == null ? '' : this.followUpEs.next_time.toString().substr(0, 10);
        FollwupAdd({
          customer_id: Number(this.followUpId),
          customer_data_id: Number(this.followUpIdES),
          content: this.followUpEs.theme,
          type: Number(this.followUpEs.type),
          next_time: next_time,
          cname: this.followUpEs.name
        }).then(function (res) {
          //入学时间
          _this29.$notify({
            title: '成功',
            message: '新增跟进成功',
            type: 'success'
          });
          _this29.followUpUp();
          _this29.Batchstudentlevel = '';
          _this29.getList(_this29.query.page, _this29.query.pagesize);
          _this29.followUpEs.theme = '';
          _this29.followUpEs.type = 0;
          _this29.followUpEs.next_time = '';
          _this29.followUp = false;
        });
      }
    },
    RecordSure: function RecordSure() {
      this.Batchstudentlevel = '';
      this.followUp = false;
    },
    BatchModify: function BatchModify() {
      if (this.customer_data_id != '') {
        this.isShow = true;
      } else {
        this.$message({
          type: 'warning',
          message: '请选择客户'
        });
      }
    },
    PostMultipleUpdates: function PostMultipleUpdates(val) {
      var _this30 = this;
      //批量修改学员级别
      if (val == '') {
        this.Batchstudentlevel = '';
      } else {
        if (this.$refs.cascaderAddr.getCheckedNodes()[0].data.is_hide == 1) {
          this.$confirm('学员级别修改为' + this.$refs.cascaderAddr.getCheckedNodes()[0].label + '时，则私海数据中不再展示，确认修改？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this30.StudentLevel(val);
          });
        } else {
          this.StudentLevel(val);
        }
      }
    },
    StudentLevel: function StudentLevel(val) {
      var _this31 = this;
      this.Batchstudentlevel = val[1] == undefined ? val[0] : val[1];
      if (this.customer_data_id != '') {
        MyCustomerSetlevel({
          customer_data_ids: String(this.customer_data_id),
          customer_level: String(this.Batchstudentlevel)
        }).then(function (res) {
          _this31.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success'
          });
          _this31.getList(_this31.query.page, _this31.query.pagesize);
        });
      } else {
        this.$message({
          type: 'warning',
          message: '请选择客户'
        });
      }
    },
    revisitDays: function revisitDays() {
      if (this.customer_data_id != '') {
        this.revisit = true;
      } else {
        this.$message({
          type: 'warning',
          message: '请选择客户'
        });
      }
    },
    revisitlevelEs: function revisitlevelEs(val) {
      var _this32 = this;
      //批量修改下次回访时间
      FollwupSettime({
        customer_data_ids: String(this.customer_data_id),
        next_time: this.revisitlevel.toString().substr(0, 10)
      }).then(function (res) {
        _this32.$notify({
          title: '成功',
          message: '修改成功',
          type: 'success',
          duration: 2000
        });
        _this32.followUpUp();
        _this32.$nextTick(function () {
          _this32.getList(_this32.query.page, _this32.query.pagesize);
        });
      });
    },
    Deletes: function Deletes() {
      //隐藏批量学员级别修改
      this.isShow = false;
    },
    DeletesEs: function DeletesEs() {
      //隐藏批量下次回访时间
      this.revisit = false;
      // this.parentNode.remove();
    },
    //下载方法
    downloadExcel: function downloadExcel(res) {
      var fileName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '未命名.xlsx';
      var a = document.createElement('a');
      var blob = new Blob([res.data], {
        type: 'application/vnd.ms-excel'
      });
      var url = URL.createObjectURL(blob);
      a.setAttribute('href', url);
      a.setAttribute('download', fileName);
      a.click();
    },
    ExcelModel: function ExcelModel() {
      var _this33 = this;
      //下载标准表
      MyExcelModel({
        responseType: 'blob'
      }).then(function (res) {
        _this33.downloadExcel(res, '客户信息标准表.xlsx');
      });
    },
    infodata: function infodata() {
      this.$notify({
        title: '',
        message: '敬请期待',
        type: 'success',
        duration: 2000
      });
    },
    goTodetails: function goTodetails(row, val) {
      //客户详情
      var routeUrl = this.$router.resolve({
        path: '/myCustomerDetail?Cid=' + row.customer_id + '&CData_id=' + row.customer_data_id + '&editRole=1&key=' + val.$index + '&page=' + this.query.page + '&pagesize=' + this.query.pagesize + '&list_type=' + this.list_type
      });
      var asas = JSON.stringify(this.resultES);
      localStorage.setItem('resultES', asas);
      window.open(routeUrl.href, '_blank');
    },
    deleteBtn: function deleteBtn() {
      var _this34 = this;
      //删除客户
      if (this.tempId != '') {
        this.$confirm('此操作将永久删除该客户, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {}).catch(function () {
          _this34.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message({
          type: 'info',
          message: '请选择院校'
        });
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //获取选中id
      this.userDetails = val;
      this.multipleSelection = val;
      var idEs = [];
      var dataId = [];
      for (var i = 0; i < val.length; i++) {
        dataId.push(val[i].customer_data_id);
        idEs.push(val[i].customer_id);
        this.is_top = val[i].is_top;
        this.userName = val[i].cname;
      }
      this.customer_data_id = _toConsumableArray(new Set(dataId));
      this.international = _toConsumableArray(new Set(dataId));
      this.customer_id = _toConsumableArray(new Set(idEs));
      // 单选时打开此代码
      // if (val.length > 1) {
      //     this.$refs.multipleTable.clearSelection();
      //     this.$refs.multipleTable.toggleRowSelection(val.pop());
      // }
    },
    // 报考项目下拉数据
    getIttnxm: function getIttnxm() {
      var _this35 = this;
      _getIttnxm().then(function (res) {
        //报考项目
        _this35.projects = res.data;
      });
    },
    onUpdateQueryFun: function onUpdateQueryFun() {
      this.getList(this.query.page, this.query.pagesize);
    }
  },
  destroyed: function destroyed() {
    var _this = this;
    document.removeEventListener('visibilitychange', _this.handleVisiable);
  }
};